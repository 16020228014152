<template>
  <div
    v-for="(row, rowIndex) in matrix.data"
    :class="galleryStyles(row.length)"
  >
    <MediaThumb
      v-for="item in row"
      :key="item.id"
      :class="mediaClass(matrix, rowIndex)"
      :blurhash="item.blurhash"
      :creator="creator"
      :duration="$secondsToTime(item.durationInSeconds)"
      :locked="item.locked"
      :originalHeight="item.height"
      :originalWidth="item.width"
      :src="item.signedUrl"
      :thumbnail="item.signedThumbnailUrl"
      :type="item.type"
      :modalEnabled="false"
      :overrideLock="overrideLock"
      :status="item.status"
      :rejected="item.rejected"
      :hasAccess="item.hasAccess"
      @click="handleMediaClick(item, price)"
    />
  </div>
</template>

<script setup>
  /******************************************************
   *   	Imports
   *****************************************************/
  import { useUserStore } from "@/store/user"
  import { MediaModal } from "#components"

  const loginModalOpen = inject("loginModalOpen")

  /******************************************************
   *   	Setup Define
   *****************************************************/
  const props = defineProps({
    postId: {
      type: String,
      required: true,
    },
    creator: {
      type: Object,
      required: true,
    },
    media: {
      type: Array,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      required: true,
    },
    price: {
      type: Number,
      default: 0,
    },
    overrideLock: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: null,
    },
  })

  const emit = defineEmits(["post-purchase"])

  /******************************************************
   *   	Composables, Refs & Variables
   *****************************************************/
  const userStore = useUserStore()
  const modal = useModal()

  /******************************************************
   *   	Computed / Functions
   *****************************************************/
  const matrix = computed(() => {
    let data = props.overrideLock
      ? props.media
      : props.media.filter((item) => item.status != "Rejected")

    const cols = 3
    const freeMedia = data.filter((item) => item.locked == false)
    const lockedMedia = data.filter((item) => item.locked)

    let items = []
    if (props.preview) {
      items = [...freeMedia, ...lockedMedia].slice(0, 4)
    } else {
      items = [...freeMedia, ...lockedMedia]
    }

    let media = []
    for (let i = 0; i < items.length; i += cols) {
      media.push(items.slice(i, i + cols))
    }

    return {
      total: items.length,
      rows: media.length,
      data: media,
    }
  })

  const galleryStyles = (size) => {
    let styles = "grid gap-2"
    styles += ` grid-cols-${size == 1 ? 3 : size}`
    return styles
  }

  const mediaClass = (matrix, rowIndex) => {
    const isLastRow = matrix.data.length - 1 == rowIndex

    // handle single media
    if (matrix.total == 1) {
      return "aspect-[1/1] sm:aspect-[16/9] col-span-3"
    }
    // handle two pieces of media
    else if (matrix.total == 2) {
      return "aspect-[1/1] h-[250px] w-full"
    }
    // handle last row having a single image
    else if (isLastRow && matrix.data[rowIndex].length == 1) {
      return "aspect-[16/9] sm:aspect-[16/9] col-span-3 h-[250px] w-full"
    }
    // handle last row having two images
    else if (isLastRow && matrix.data[rowIndex].length == 2) {
      return "aspect-[1/1] h-[250px] w-full"
    }
    // everything else
    else {
      return "aspect-[1/1]"
    }
  }

  const openMediaModal = (price, media, gallery = []) => {
    modal.open(MediaModal, {
      mode: "post",
      referenceId: props.postId,
      media: media,
      gallery: gallery,
      editable: false,
      price: price,
      creator: props.creator,
      createdAt: props.date,

      /* Emit Listeners */
      onClose() {
        modal.close()
      },
      onEdit() {
        console.log("MediaModal Edit Clicked")
      },
      onPostPurchase(postId) {
        emit("post-purchase", postId)
        modal.close()
      },
    })
  }

  const handleMediaClick = (item, price) => {
    if (userStore.loggedIn) {
      openMediaModal(price, item, props.media)
    } else {
      // launch login modal
      loginModalOpen.value = true
    }
  }
</script>
